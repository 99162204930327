import {
    Box,
    HStack,
    Badge,
    IconButton,
    Collapse,
    useDisclosure,
    Stack,
    useBreakpointValue,
    Text

} from '@chakra-ui/react';
import { MinusIcon, ChevronDownIcon } from '@chakra-ui/icons';
import React from 'react';
import GenericItineraryCard from './GenericItineraryCard';

interface ItineraryCardProps {
    dayItinerary: any;
}

const ItineraryCard: React.FC<ItineraryCardProps> = ({ dayItinerary }) => {
    const { isOpen, onToggle } = useDisclosure();
    const icon = useBreakpointValue({
        base: isOpen ? <MinusIcon boxSize={4} /> : <></>,
        md: <></>,
        lg: isOpen ? <></> : <ChevronDownIcon boxSize={4} />,
    });

    return (
        <Stack bg={["#FFFFFF12", "#FFFFFF12", "none"]} mt={'15px'} w='full' borderRadius={'4px'} onClick={onToggle} _hover={{ cursor: "pointer" }}>
            <Box
                display={["none", "none", "block"]}
                borderTop={'1px solid #FFFFFF21'}
                mt={[4, 0, 0]}
            />
            <HStack
                alignItems='center'
                justifyContent="space-between"
                mx={[3, 3, 0]} mt={[isOpen ? 4 : 3, isOpen ? 4 : 3, isOpen ? 4 : 2]}
                mb={[isOpen ? 0 : 3, isOpen ? 0 : 3, 0]}>

                <Badge
                    bg={isOpen ? ['#AE7CFF', '#AE7CFF', 'none'] : ['#FFFFFF21', '#FFFFFF21', 'none']}  // Conditional color change only for small screens
                    color={["#FFFFFFD4", "#FFFFFFD4", "#FFFFFFED"]}
                    px={[4, 4, 0]}
                    py={2}

                    maxW={"80%"}
                    textTransform={"capitalize"}
                    rounded={['full', 'full', 'none']}  // Rounded only on small screens
                    fontSize={["13px", "13px", "33px"]}  // Apply larger font size for larger screens
                >
                    <Text
                        fontFamily={'Poppins'}
                        isTruncated={isOpen ? false : true}
                        noOfLines={isOpen ? 2 : 1}
                        whiteSpace={isOpen ? 'normal' : 'nowrap'}
                    >
                        {`Day ${dayItinerary?.day} : ${dayItinerary?.title}`}
                    </Text>
                </Badge>
                <IconButton
                    aria-label={isOpen ? 'Collapse' : 'Expand'}
                    icon={icon}
                    bg={[isOpen ? '#FFFFFF0D' : 'none', 'none', 'none']}
                    color='#FFFFFFA1'
                    borderRadius='full'
                    onClick={onToggle}
                    _hover={{ bg: '#none' }}
                />
            </HStack>
            <Collapse in={isOpen} animateOpacity>
                <Box px={[4, 4, 0]}>
               
                    <GenericItineraryCard
                        // title={dayItinerary?.title}
                        accommodation={dayItinerary?.accommodation}
                    />

                    {dayItinerary?.transportation?.map((item: any, index: number) => (
                        <>
                            <Box
                                borderBottom={'1px solid #FFFFFF21'}
                                mt={[4, 4, 8]}
                                mb={[4, 4, 8]}
                            />
                            <GenericItineraryCard
                                key={index}
                                title={item?.description}
                                locationFrom={item?.from}
                                locationTo={item?.to}
                                maps_route={item?.maps_route}
                                costINR={item?.costINR}
                            />
                        </>
                    ))}
                    {dayItinerary?.activities && (
                        <>
                            <GenericItineraryCard
                                title='Activities'
                                activities={dayItinerary?.activities} />
                        </>
                    )}
                    {dayItinerary?.eateries && (
                        <>  <Box
                            borderBottom={'1px solid #FFFFFF21'}
                            mt={[4, 4, 8]}
                            mb={[4, 4, 8]}
                        />
                            <GenericItineraryCard
                                activities={dayItinerary?.eateries} />
                        </ >
                    )}

                    {dayItinerary?.miscellaneous && (
                        <Box mb={2}>
                            <Box
                                borderBottom={'1px solid #FFFFFF21'}
                                mt={[4, 4, 8]}
                                mb={[4, 4, 8]}
                            />
                            <GenericItineraryCard
                                title='Miscellaneous'
                                details={dayItinerary?.miscellaneous}
                            />
                        </Box>

                    )}
                  
                </Box>
            </Collapse>
        </Stack>
    );
};

export default ItineraryCard;
