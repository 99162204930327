import { Box, IconButton, Image } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import InfluencerVideo1 from "./InfluencerVideo1";
import { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { useLoading } from "../../context/LoadingContext";

const BackButton: React.FC = () => {
  const navigate = useNavigate();
  const routerLocation = useLocation();

  // const location = useLocation();
  // const prevLocation = useRef(null);

  // Update the ref with the current location on each render
  // useEffect(() => {
  // 	//@ts-ignore
  // 	prevLocation.current = location.pathname;
  // }, [location]);

  return (
    <IconButton
      position="fixed"
      aria-label="back"
      top="4%"
      left="3%"
      zIndex={11}
      icon={<Image src="/icons/Back_Icon_White.svg" alt="back" />}
      variant="ghost"
      size="lg"
      onClick={() => {
        if (routerLocation.key === "default") {
          navigate("/"); // Navigate to root if there is no history
        } else {
          navigate(-1); // Navigate back in history
        }
      }}
      borderRadius="full"
      dropShadow="0px 4px 10px rgba(0, 0, 0, 0.6)"
      _hover={{ bg: "transparent" }}
      _active={{ bg: "transparent" }}
      _focus={{ boxShadow: "none" }}
    />
  );
};

const InfluencersVideo: React.FC = () => {
  const { fetchData } = useAxios();
  const [showBackButton, setShowBackButton] = useState(true);
  const { setIsLoading } = useLoading();

  const [influencers, setInfluencers] = useState<any>({
    channelName: "",
    chapter_segment: "[]",
    highlights: [],
    score: 0.0,
    source: "",
    tags: [],
    video_url: "",
    preview_image: "",
    video_play_url: {
      title: "",
      is_audio: false,
      author: "",
      source: "",
      previewImage: "",
      media_url: "",
    },
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const location = useLocation();
  const clicked = location.state?.clicked ?? "";
  const videos = location.state?.videos ?? [];
  const index = location.state?.videoUrl ?? location.state?.index ?? 0;

  const influencerDataState = location.state?.influencerData ?? {
    channelName: "",
    chapter_segment: "[]",
    highlights: [],
    score: 0.0,
    source: "",
    tags: [],
    video_url: "",
    video_play_url: {
      title: "",
      is_audio: false,
      author: "",
      source: "",
      previewImage: "",
      media_url: "",
    },
  };

  const queryString = location.search.slice(1); // Remove the '?' from the query string
  const params = new URLSearchParams(queryString);

  // Get the base `link` value
  let link = params.get("link");

  // Check if additional parameters exist (e.g., 'v' belongs to 'link')
  if (link && queryString.includes("v=")) {
    const remainingQuery = queryString.split("link=")[1]; // Extract everything after 'link='
    link = decodeURIComponent(remainingQuery); // Decode the full reconstructed value
  }
  const isBucketList = location.state?.isBucketList ?? false;
  // console.log("I am here", link);

  const fetchVideoDetail = async () => {
    const { data, statusCode, error } = await fetchData({
      method: "POST",
      url: "ai/video-link-data",
      options: {
        data: {
          videos: [link],
          video_metadata_id: [
            location?.state?.video_metadata_id.length > 0
              ? location?.state?.video_metadata_id
              : undefined,
          ],
        },
        headers: { "Content-Type": "application/json" },
      },
      auth: false,
    });

    if (statusCode === 200) {
      if (data && data.videos) {
        window.scrollTo(0, 0);
        await setInfluencers(data.videos);
        setIsLoading(false);
      }
    } else {
      console.log(error);
    }
  };

  const fetchVideo = async () => {
    const { data, statusCode, error } = await fetchData({
      method: "POST",
      url: "ai/video-link-data",
      options: {
        data: {
          videos: [videos[currentIndex]["url"]],
          video_metadata_id: [videos[currentIndex]["video_metadata_id"]],
        },
        headers: { "Content-Type": "application/json" },
      },
      auth: false,
    });

    if (statusCode === 200) {
      if (data && data.videos) {
        window.scrollTo(0, 0);
        await setInfluencers((prevInfluencers: any) => {
          const updatedInfluencers = [...prevInfluencers];
          updatedInfluencers[currentIndex] = data.videos[0];
          return updatedInfluencers;
        });
        setIsLoading(false);
      }
    } else {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!link && !location.state?.isBucketList) {
      const index = influencerDataState?.findIndex(
        (item: any) => item?.video_url === clicked?.video_url
      );
      setCurrentIndex(index !== -1 ? index : 0);
    }
    // eslint-disable-next-line
  }, [influencerDataState]);

  useEffect(() => {
    // alert(link);
    if (link) {
      setIsLoading(true);
      console.log("I am here111111");
      fetchVideoDetail();
      setCurrentIndex(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const viewportHeight = 60;

      // console.log(`Scroll Position: ${scrollPosition}`);
      // console.log(`Viewport Height: ${viewportHeight}`);

      if (scrollPosition > viewportHeight) {
        console.log("Hiding back button");
        setShowBackButton(false);
      } else {
        console.log("Showing back button");
        setShowBackButton(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (location.state?.isBucketList) {
      console.log("I am here2222222", index);
      setCurrentIndex(index);
      setInfluencers(videos);
    }
    // eslint-disable-next-line 
  }, []);

  useEffect(() => {
    if (isBucketList) {
      setIsLoading(true);
      fetchVideo();
    }
    // eslint-disable-next-line
  }, [currentIndex]);

  return (
    <Box>
      {showBackButton && <BackButton />}
      <InfluencerVideo1
        influencers={link || isBucketList ? influencers : influencerDataState}
        currentIndex={currentIndex}
        isLink={link === null ? false : true}
        isBucketList={isBucketList}
        handleNext={() => {
          if (!isBucketList) {
            setCurrentIndex((prev) => (prev + 1) % influencerDataState.length);
          } else {
            setCurrentIndex((prev) => (prev + 1) % videos.length);
          }
        }}
        handlePrevious={() => {
          if (!isBucketList) {
            setCurrentIndex(
              (prev) =>
                (prev - 1 + influencerDataState.length) %
                influencerDataState.length
            );
          } else {
            setCurrentIndex(
              (prev) => (prev - 1 + videos.length) % videos.length
            );
          }
        }}
      />
    </Box>
  );
};
export default InfluencersVideo;
