import React, {  useContext, useEffect, useState } from "react";
import {
    IconButton,
    Box,
    Button,
    Flex,
    HStack,
    Image,
    Text,
    useDisclosure,
} from "@chakra-ui/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TripCard from "../components/CreateItinary/TripCard";
import TripDetail from "../components/CreateItinary/TripDetail";
import { AppContext } from "../context/AppContext";
import { useConversationService } from "../services/conversationService";
import useAxios from "../hooks/useAxios";
import SavingComponent from "../components/CreateItinary/SavingComponent";
import * as amplitude from "@amplitude/analytics-browser";
import { useLoading } from "../context/LoadingContext";
import ModalWithBackdrop from "../components/ModalWithBackdrop";
import SigninComponent from "../components/SigninComponent";
import useSmartNavigate from "../hooks/useSmartNavigate";
// import data from '../components/CreateItinary/data.json';

const Itinerary: React.FC = () => {
    // const { navigateSmart } = useNavigationHistory();
    const { id } = useParams<{ id: string }>();
    const router = useNavigate();
    const navigate = useSmartNavigate();
    const location = useLocation();
    const [isSaved, setIsSaved] = useState(false);
    const context: any = useContext(AppContext);
    const { setOnBehalf, setConversationId, setTitle, setPreviousChat, setItinerary, itinerary, shareModal, conversationId, currItinerary } = context;
    const [destination, setDestination] = useState(location?.state?.itinerary?.trip_itinerary);
    const { fetchData } = useAxios();
    const [status, setStatus] = useState('');
    const [toShow, setToShow] = useState(false);
    const [itn, setItn] = useState(location?.state?.itinerary);
    const { getConversationById, processConversationMessages } = useConversationService();
    const [conversation_id, setConversation_id] = useState(location?.state?.conversation_id ?? itn?.conversation_id);
    const message_id = location?.state?.message_id ?? itn?.message_id;
    const myAppUrl = process.env.REACT_APP_URL;
    const { setIsLoading } = useLoading();
    const routerLocation = useLocation();
    const { isOpen, onOpen, onClose } = useDisclosure();


    const handleOnFollowUp = async () => {
        if (!localStorage.getItem("token"))
            return onOpen();

        setOnBehalf({
            message_id: message_id,
            itinerary_id: itn?.id,
            title: destination?.title,
            subtitle: destination?.subtitle,
            imageUrl: destination?.image,

        });
        if (conversation_id === conversationId) {
            navigate('/conversation');
            return;
        }
        else {
            setTitle("");
            setConversationId(conversation_id);

            await setPreviousChat([]);
            navigate('/conversation');
            const data = await getConversationById({ conversation_id, type: "itinerary" });

            if (data) {
                setConversationId(data.conversation_id);
                setTitle(data.title);
                const transformedMessages = processConversationMessages(data.messages);
                setPreviousChat(transformedMessages);
            }
        }
    };

    const fetchItineraryById = async (itineraryId: string) => {
        setIsLoading(true);
        try {
            const { statusCode, data } = await fetchData({
                method: "POST",
                url: "get_itinerary_by_id",
                options: {
                    data: {
                        itinerary_id: itineraryId
                    },
                    headers: { "Content-Type": "application/json" },
                },
                auth: false,
            });

            if (statusCode === 200 && data) {
                setDestination(data?.itinerary?.plan?.trip_itinerary);
                const temp = { 
                    ...data?.itinerary?.plan, 
                    id: data?.itinerary?.itinerary_id, 
                    conversation_id: data?.itinerary?.conversation_id 
                };
                setItn(temp);
                setConversation_id(data?.itinerary?.conversation_id);

                // Check if this itinerary is already saved
                if (itinerary) {
                    const isSavedItinerary = itinerary.some((trip: any) => trip.id === data.id);
                    setIsSaved(isSavedItinerary);
                }
            }
        } catch (error) {
            console.error("Error fetching itinerary:", error);
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        console.log("itinerary", itn);
        if (itinerary) {
            for (let trip of itinerary) {
                console.log("trip", trip);
                if (trip?.id === itn?.id) {

                    setIsSaved(true);
                    return;
                }
            }
            // console.log("trip", itinerary);
        }
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        if (id && (!location?.state?.itinerary || location?.state?.itinerary?.id !== id)) {
            fetchItineraryById(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (currItinerary && currItinerary.length > 0 && currItinerary[0].conversation_id === conversation_id) {
            setDestination(currItinerary[0].plan.trip_itinerary);
            setItn(currItinerary[0].plan);
            setConversation_id(currItinerary[0].conversation_id);
        }
    }, [currItinerary]);

    // useEffect(() => {
    //     if (location?.state?.itinerary) {
    //         setDestination(location?.state?.itinerary?.trip_itinerary);
    //         setItn(location?.state?.itinerary);
    //     }
    // }, [location?.state?.itinerary]);


    if (!destination) {
        return (
            <Box
                bg="linear-gradient(118.03deg, #272529 5.68%, #111012 59.38%)"
                minH="100vh"
                w="100vw"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Box color="white" fontSize="xl">
                    Destination not found
                </Box>
            </Box>
        );
    }

    const handleItinerarySave = async () => {
        const { statusCode } = await fetchData({
            method: "POST",
            url: "ai/v1/save_itinerary",
            options: {
                data: {
                    token: localStorage.getItem("token"),
                    itinerary_id: itn?.id ?? id,
                    conversation_id: conversation_id
                },
                headers: { "Content-Type": "application/json" },
            },
            auth: true,
        });
        if (statusCode === 200) {
            setIsSaved(true);
            setStatus('saved');
            setTimeout(() => {
                setToShow(false);
            }, 3000);
            setItinerary((prevItinerary: any[] = []) => [...prevItinerary, itn]);
        }
    };

    const handleSave = () => {
        if (!localStorage.getItem("token"))
            return onOpen();
        if (isSaved) return;
        amplitude.track("button_clicked", {
            buttonName: "save_itinerary",
            itinerary_title: destination?.title,
        });
        setToShow(true);
        setStatus('saving');
        handleItinerarySave();

    };

    const handleShare = async () => {
        const shareUrl = `${myAppUrl}/itineraries/${itn?.id ?? id}`;
        shareModal.handleShareClick(shareUrl);
    };



    return (
        <>
            <ModalWithBackdrop isOpen={isOpen} onClose={onClose}>
                <SigninComponent onClose={onClose} />
            </ModalWithBackdrop>
            <Box
                bg={[
                    "#141315",
                    "#141315",
                    "linear-gradient(118.03deg, #272529 5.68%, #111012 59.38%)",
                ]}
                minH="100vh"
                w="100vw"
                position="relative"
                overflow="hidden"

            >

                {toShow && (<SavingComponent status={status} />)}

                <Box
                    mt={["0", "0", "4.5vh"]}
                    mb={"8vh"}
                    height={"auto"}
                    pl={["0px", "0px", "18%"]}
                    pr={["0px", "0px", "17%"]}
                >
                    <Flex
                        justify="space-between"
                        align="center"
                        maxH="68px"
                        p="16px"
                        visibility={["visible", "visible", "hidden"]}
                    >
                        <Box>
                            <IconButton
                                aria-label="Back"
                                icon={<Image src="/icons/Back_Icon_White.svg" alt="back" />}
                                colorScheme="gray"
                                variant={"link"}
                                size="lg"
                                onClick={() => {
                                    if (routerLocation.key === "default") {
                                        navigate("/"); // Navigate to root if there is no history
                                    } else {
                                        router(-1); // Navigate back in history
                                    }
                                }}
                            />
                        </Box>
                        <HStack spacing="10px">
                            <Button
                                fontSize="13px"
                                fontWeight="600"
                                color="#AE7CFF"
                                variant="link"
                                onClick={handleSave}
                            >
                                {isSaved ? "Itinerary Saved" : "+ Save Itinerary"}
                            </Button>
                            <Button bg="inherit" variant="link" onClick={handleShare}>
                                <img src={"/icons/share3.svg"} alt="share Icon" />
                            </Button>
                        </HStack>
                    </Flex>
                    <TripCard
                        title={destination?.title}
                        dates={destination?.dates}
                        number_of_people={destination?.number_of_people}
                        onEdit={() => {
                            console.log("Edit button clicked");
                        }}
                        onAddTickets={() => {
                            console.log("Add tickets button clicked");
                        }}
                    />
                    <TripDetail
                        title={destination?.title}
                        subtitle={destination?.subtitle}
                        description={destination?.description}
                        destination={destination}
                        itinerary={itn}
                    />
                </Box>
                <Flex
                    position="fixed"
                    bottom="2vh"
                    left="50%"
                    transform="translateX(-50%)"
                    zIndex={100}
                    bg={"#252427D4"}
                    w={"max-content"}
                    borderRadius={"99px"}
                    h={"56px"}
                    alignItems={"center"}
                    border="linear-gradient(92.73deg, #8B8B8B 6.17%, #202020 97.11%),
        linear-gradient(272.54deg, rgba(0, 0, 0, 0) 8.68%, #614C75 99.06%)"
                >
                    <Button
                        py={"18.5px"}
                        ml={"-6px"}
                        leftIcon={
                            <Image
                                src="/icons/Centre_Button.svg"
                                alt="icon"
                                boxSize="42.86"
                                border="0.3px solid linear-gradient(112.57deg, #FBF7F7 4.82%, #2E2929 104.38%)"
                            />
                        }
                        variant={"ghost"}
                        _hover={{ bg: "none" }}
                        onClick={handleOnFollowUp}
                    >
                        <Text
                            bgGradient="linear-gradient(90deg, #FFFFFF 63.24%, #666262 115.52%)"
                            bgClip={"text"}
                            fontFamily={"Poppins"}
                            fontSize={"15px"}
                            fontWeight={600}
                            letterSpacing={"=-0.56px"}
                        >
                            Customise Itinerary
                        </Text>
                    </Button>
                </Flex>
            </Box>
        </>
    );
};

export default Itinerary;
