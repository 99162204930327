import { Image, Text, VStack, useBreakpointValue, Box, Flex } from "@chakra-ui/react";
import GenericItineraryCard from "./GenericItineraryCard";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { useLoading } from "../../context/LoadingContext";
import { fetchEntityDetails } from "../../services/apiService";
import useSmartNavigate from "../../hooks/useSmartNavigate";
interface AlternatingCardProps {
    activities: any;
}
const AlternatingCard: React.FC<AlternatingCardProps> = ({
    activities,
}) => {
    const isMobile = useBreakpointValue({ base: true, md: false });

    const context: any = useContext(AppContext);
    const {
        userDetail,
        setStryIndex,
        setInfluencerData,
        setPreviousChatLoading,
        setEntitesDetail,
    } = context;
    const { setIsLoading } = useLoading();
    const navigate = useSmartNavigate();


    const handleClick = async (placeName: any, city: any, image: any) => {
        // Handle navigation or entity fetch logic here
        try {
            setStryIndex(null);
            setInfluencerData([]);
            setIsLoading(true);
            setEntitesDetail([]);

            const initialEntityDetail = await fetchEntityDetails(
                placeName,
                userDetail,
                city ?? "",
                "",
                {},
                "",
            );

            console.log("response is:", JSON.stringify(initialEntityDetail.data));
            setPreviousChatLoading(true);

            navigate(`/story-carousel`, {
                state: {
                    clickedIndex: 0,
                    initialEntityDetail: initialEntityDetail.data.entity,
                    allEntities: [{ title: placeName, images: image ?? "" }],
                    city: "",
                    country: "",
                    videoHighlight: true,
                    itinerary: true
                }
            });
        }
        catch (error) {
            console.error('Fetch error:', error);
        }
        finally {
            setIsLoading(false);
        }
    }
    return (
        <VStack spacing={4} align="stretch" w="full">
            {activities.map((item: any, index: number) => (
                <Box key={index} fontFamily={'Poppins'}>
                    {item?.type !== "transport" ? (
                        <Box
                            // py={3}
                            px={2}
                            color="#FFFFFFA1"
                        >
                            <Flex
                                direction={isMobile ? "row" : "column"}
                                gap={isMobile ? 3 : 4}
                                alignItems="flex-start"
                            >
                                <Image
                                    src={item?.image}
                                    alt={item?.name}
                                    border={'1px solid #313030'}
                                    width="85px"
                                    height="85px"
                                    borderRadius="md"
                                    objectFit="cover"
                                    flexShrink={0}
                                    onClick={() => {
                                        handleClick(item?.place_name, item?.city, item?.image);
                                    }}
                                />
                                <VStack spacing={1} alignItems="flex-start" width="full" pt={isMobile ? 0 : 1}>
                                    <Flex w="full" justifyContent="space-between" alignItems="flex-start">
                                        <Text
                                            w={"80%"}
                                            fontFamily={'Poppins'}
                                            fontSize={'16px'}
                                            fontWeight={'medium'}
                                            color={'#FFFFFFD4'}
                                            lineHeight={'24px'}
                                            cursor="pointer"
                                        >
                                            {item?.name}
                                        </Text>
                                        {item?.mapslink && (
                                            <Image
                                                src="/icons/map_pin.svg"
                                                alt="map icon"
                                                onClick={() => { window.open(item?.mapslink, '_blank') }}
                                                boxSize={7}
                                                cursor="pointer"
                                                _hover={{ opacity: 0.8 }}
                                            />)}
                                    </Flex>
                                    <VStack
                                        spacing={1}
                                        alignItems={"flex-start"}
                                    >
                                        <Text
                                            fontFamily={'Poppins'}
                                            fontSize={'12px'}
                                            fontWeight={'normal'}
                                            color={'#FFFFFFA1'}
                                            lineHeight={'18px'}
                                        >
                                            {item?.description}
                                        </Text>
                                        {item?.cuisine && (
                                            <Text
                                                fontFamily={'Poppins'}
                                                fontSize={'12px'}
                                                fontWeight={'normal'}
                                                color={'#FFFFFFA1'}
                                                lineHeight={'18px'}
                                            >
                                                <Text as="span" fontWeight={'medium'}>Cuisine: </Text>{item?.cuisine}
                                            </Text>
                                        )}
                                        {item?.reasoning && (
                                            <Text
                                                fontFamily={'Poppins'}
                                                fontSize={'12px'}
                                                fontWeight={'normal'}
                                                color={'#FFFFFFA1'}
                                                lineHeight={'18px'}
                                            >
                                                <Text as="span" fontWeight={'medium'}>Reasoning: </Text>{item?.reasoning}
                                            </Text>
                                        )}
                                        {item?.duration && (
                                            <Text
                                                fontFamily={'Poppins'}
                                                fontSize={'12px'}
                                                fontWeight={'normal'}
                                                color={'#FFFFFFA1'}
                                                lineHeight={'18px'}
                                            >
                                                <Text as="span" fontWeight={'medium'}>Duration: </Text>{item?.duration}
                                            </Text>
                                        )}
                                        {(item?.costINR && item?.costINR !== 0 && item?.costINR !== '0') && (
                                            <Text
                                                fontFamily={'Poppins'}
                                                fontSize={'12px'}
                                                fontWeight={'normal'}
                                                color={'#FFFFFFA1'}
                                                lineHeight={'18px'}
                                            >
                                                <Text as="span" fontWeight={'medium'}>Cost: ₹</Text>{item?.costINR}
                                            </Text>
                                        )}
                                        {item?.booking_link && (
                                            <Text
                                                fontFamily={'Poppins'}
                                                fontSize={'12px'}
                                                fontWeight={'medium'}
                                                color={'#AE7CFF'}
                                                lineHeight={'18px'}
                                                cursor="pointer"
                                                _hover={{ textDecoration: "underline" }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    window.open(item?.booking_link, '_blank');
                                                }}>
                                                Book here
                                            </Text>
                                        )}
                                    </VStack>
                                </VStack>
                            </Flex>
                        </Box>
                    ) : (
                        <GenericItineraryCard
                            title={item?.name}
                            locationFrom={item?.from}
                            locationTo={item?.to}
                            maps_route={item?.maps_route}
                            directions_link={item?.directions_link}
                            costINR={item?.costINR}
                            details={item?.description}
                        />
                    )}

                    {index < activities.length - 1 && (
                        <Box
                            borderBottom={'1px solid #FFFFFF21'}
                            mt={4}
                            // mb={4}
                            
                        />
                    )}
                </Box>
            ))}
        </VStack>
    );
};

export default AlternatingCard;