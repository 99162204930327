import {
	Box, SimpleGrid, VStack, Text, Image, HStack,
	//  useDisclosure
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import { fetchEntityDetails } from '../../services/apiService';
import { useLoading } from '../../context/LoadingContext';
import useSmartNavigate from '../../hooks/useSmartNavigate';

// import useAxios from '../../hooks/useAxios';
// import ModalWithBackdrop from '../ModalWithBackdrop';
// import Option from './Option';

interface ImageViewProps {
	image?: string;
	name?: string;
	address?: string;
	costINR?: string;
	booking_link?: string;
	linkUrl?: string;
	width?: string;
	height?: string;
	mapslink?: string;
	place_name?: string;
	city?: string;
}

interface ImageGridProps {
	accommodation: any;
}

const ImageView: React.FC<ImageViewProps> = ({
	image,
	name,
	address,
	costINR,
	booking_link,
	linkUrl,
	width,
	height,
	mapslink,
	place_name,
	city,
}) => {
	const context: any = useContext(AppContext);
	const {
		userDetail,
		setStryIndex,
		setInfluencerData,
		setPreviousChatLoading,
		setEntitesDetail,
	} = context;
	const { setIsLoading } = useLoading();
	const navigate = useSmartNavigate();
	// const { fetchData } = useAxios();
	// const { isOpen, onOpen, onClose } = useDisclosure();
	// const url = window.location.href;
	// const itineraryId = url.split('/').pop();


	const handleClick = async (placeName: any, city: any) => {
		// Handle navigation or entity fetch logic here
		try {
			setStryIndex(null);
			setInfluencerData([]);
			setIsLoading(true);
			setEntitesDetail([]);

			const initialEntityDetail = await fetchEntityDetails(
				placeName,
				userDetail,
				city ?? "",
				"",
				{},
				"",
			);

			console.log("response is:", JSON.stringify(initialEntityDetail.data));
			setPreviousChatLoading(true);

			navigate(`/story-carousel`, {
				state: {
					clickedIndex: 0,
					initialEntityDetail: initialEntityDetail.data.entity,
					allEntities: [{ title: placeName, images: image }],
					city: "",
					country: "",
					videoHighlight: true,
					itinerary: true
				}
			});
		}
		catch (error) {
			console.error('Fetch error:', error);
		}
		finally {
			setIsLoading(false);
		}
	}
	// const handleChange = async () => {

	// 	onOpen();			
	// };
	return (
		<Box>
			<VStack align='start' spacing={0}>
				<Image
					mt='20px'
					mb='12px'
					src={image}
					borderRadius='12px'
					objectFit='cover'
					w={width}
					h={height}
					onClick={() => {
						handleClick(place_name, city);
					}}
				/>
				<HStack w={'full'} justifyContent='space-between' alignItems='flex-start'>
					{name && (
						<Text
							w={"80%"}
							fontFamily={'Poppins'}
							fontSize={'16px'}
							fontWeight={'medium'}
							color={'#FFFFFFD4'}
							lineHeight={'24px'}
						>
							{name}
						</Text>
					)}

					{mapslink && (
						<Image
							maxW={'auto'}
							src="/icons/map_bg.svg"
							alt="map icon"
							onClick={() => {
								window.open(mapslink, '_blank');
							}}
							boxSize={7}
						/>
					)}

				</HStack>
				{address && costINR
					&& (
						<Text
							fontFamily={'Poppins'}
							fontSize={'12px'}
							fontWeight={'normal'}
							color={'#FFFFFFA1'}
							lineHeight={'18px'}
							noOfLines={1}
						>
							{address} {costINR ? `| ₹${costINR}` : ''}
						</Text>
					)}

				{booking_link && (
					<Text
						fontFamily={'Poppins'}
						fontSize={'12px'}
						fontWeight={'medium'}
						color={'#AE7CFF'}
						lineHeight={'18px'}
						cursor="pointer"
						w={'fit-content'}
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							window.open(booking_link, '_blank');
						}}
					>
						Book here
					</Text>
				)}
			</VStack>
		</Box>
	);
};

const ImageGrid: React.FC<ImageGridProps> = ({ accommodation }) => {
	const isSingleImage = accommodation?.length === 1;

	return (
		<Box>
			{isSingleImage ? (
				<>
					<ImageView {...accommodation?.[0]} width='full' height='160px' />
					<Box
						borderBottom={'1px solid #FFFFFF21'}
						mt={[4, 4, 8]}
						mb={[4, 4, 8]}
						display={accommodation?.[0]?.name ? 'block' : 'none'}
					/>
				</>
			) : (
				<>
					<SimpleGrid columns={[2, 2, 6]} spacing={'20px'} mx={1}>
						{accommodation?.map((imageProps: any, index: number) => (
							<ImageView
								key={index}
								{...imageProps}
								width='175px'
								height='120px'
							/>
						))}

					</SimpleGrid>
					<Box
						borderBottom={'1px solid #FFFFFF21'}
						mt={[4, 4, 8]}
						mb={[4, 4, 8]}
						display={accommodation?.[0]?.name ? 'block' : 'none'}
					/>
				</>
			)}
		</Box>
	);
};

export default ImageGrid;
