import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../context/AppContext';
import useAxios from '../hooks/useAxios';
import { Flex, Image, useDisclosure, Text, Button, IconButton, HStack, Box } from '@chakra-ui/react';
import ModalWithBackdrop from './ModalWithBackdrop';
import { useNavigate } from 'react-router-dom';
import SigninComponent from './SigninComponent';
import * as amplitude from '@amplitude/analytics-browser';

const LikeAndShare = () => {
	const {
		searchDetail,
		currentVideoIndex,
		// likeStatus,
		// setLikeStatus,
		shareModal,
		likeSearches,
		setLikeSearches,
	}: any = useContext(AppContext);
	const { fetchData } = useAxios();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const navigate = useNavigate();
	const myAppUrl = process.env.REACT_APP_URL;
	const [showAddedToBucket, setShowAddedToBucket] = useState(false);
	const [isLiked, setIsLiked] = useState(false);

	useEffect(() => {
		setIsLiked(false);
		console.log('searchDetail', typeof (searchDetail['videos']));
		if (searchDetail) {
			for (let video of likeSearches) {
				if (video?.url === searchDetail?.videos?.[currentVideoIndex]?.video_url || video?.url?.includes(searchDetail?.videos?.[currentVideoIndex]?.video_play_url?.video_id)) {
					setIsLiked(true);
				}
			}
		}
	}, [likeSearches, currentVideoIndex, searchDetail]);


	const likeSearch = async (isLiked: boolean) => {
		const token = localStorage.getItem('token');

		if (token) {
			const isLike = isLiked;
			const data = searchDetail['videos'];
			const body = {
				title: data[currentVideoIndex]?.title,
				video_metadata_id: data[currentVideoIndex]?.video_metadata_id,
				// city:
				// 	data[currentVideoIndex]?.city === ''
				// 		? undefined
				// 		: data[currentVideoIndex]?.city,
				// countries:
				// 	data[currentVideoIndex]?.country ?? {},
				description: data[currentVideoIndex]?.description,
				tags: data[currentVideoIndex]?.tags,
				url: data[currentVideoIndex]?.video_url,
				previewImage: data[currentVideoIndex]?.preview_image,
				location: data?.location,
				flightStartingPrice: data[currentVideoIndex]?.flightStartingPrice,
			};
			if (!isLike) {
				amplitude.track('liked', { type: "video", title: searchDetail?.videos[currentVideoIndex]?.title, location: { city: searchDetail?.videos[currentVideoIndex]?.city, country: searchDetail?.videos[currentVideoIndex]?.country }, page: "video_search", url: searchDetail?.videos[currentVideoIndex]?.video_url });
				setIsLiked(true);
				const { statusCode } = await fetchData({
					method: 'POST',
					url: 'user/likeSearch',
					options: {
						data: { ...body, token },
						headers: { 'Content-Type': 'application/json' },
					},
					auth: false,
				});


				// setLikeStatus(obj);
				// setIsLiked(true);
				if (statusCode === 200) {
					setLikeSearches([...likeSearches, body]);
					setShowAddedToBucket(true);
					setTimeout(() => {
						setShowAddedToBucket(false);
					}, 3000);
				}
				else {
					setIsLiked(false);
				}

			} else {
				amplitude.track('disLiked', { type: "video", title: searchDetail?.videos[currentVideoIndex]?.title, location: { city: searchDetail?.videos[currentVideoIndex]?.city, country: searchDetail?.videos[currentVideoIndex]?.country }, page: "video_search", url: searchDetail?.videos[currentVideoIndex]?.video_url });
				setIsLiked(false);
				const { statusCode } = await fetchData({
					method: 'POST',
					url: 'user/dislikeSearch',
					options: {
						data: { ...body, token },
						headers: { 'Content-Type': 'application/json' },
					},
					auth: false,
				});

				if (statusCode === 200) {
					const tempLikeSearches = likeSearches.filter(
						(search: any) =>
							search['url'] !== data[currentVideoIndex]?.['video_url'],
					);
					console.log('this is your seraches ; ', likeSearches);
					console.log('this is your temp like Searches ; ', tempLikeSearches);
					setLikeSearches(tempLikeSearches);
				}
				else {
					setIsLiked(true);
				}
			}
		} else {
			amplitude.track("login_triggered", { buttonName: "like", page: "video_search" });
			onOpen();
		}
	};



	const handleShareClick = useCallback(
		async (title?: any, url?: any) => {
			await fetch('https://tiny.rahee.ai/share', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ url: url }),
			})
				.then(response => response.json())
				.then(data => {
					console.log('Success:', data);
					if (data?.short_url)
						shareModal.handleShareClick(data?.short_url, title);
				})
				.catch((error) => {
					console.error('Error:', error);
				});
		},
		[shareModal],
	);

	return (
		<>
			<Box zIndex={20}>
				<ModalWithBackdrop
					isOpen={isOpen}
					onClose={() => {
						onClose();
						navigate('/');
					}}>
					<SigninComponent onClose={onClose} />
				</ModalWithBackdrop>
				<HStack spacing={0} zIndex={20} mt={-2}>
					<IconButton

						aria-label='like'
						variant="ghost"
						// size="lg"
						borderRadius="full"
						// bg={'gray.800'}
						_hover={{ bg: "transparent" }}
						// boxShadow={"0px 4px 4px 0px #00000040"}
						_active={{ bg: "transparent" }}
						_focus={{ boxShadow: "none" }}
						onClick={(e) => {
							e.stopPropagation();
							likeSearch(isLiked);
						}}>
						<Image
							src={
								isLiked ? "/icons/like.svg" : "/icons/white-heart.svg"
							}
							borderRadius="full"   
							boxShadow= {"0px 4px 4px 0px #00000040"}
							alt="like"
						/>
					</IconButton>
					<IconButton
						aria-label="Share"
						icon={<Image src="/icons/white-share.svg" alt="share"
							borderRadius="full"
							boxShadow={"0px 4px 4px 0px #00000040"}
						/>}
						variant="ghost"
						// size="lg"
						borderRadius="full"
						// boxShadow={"0px 4px 4px 0px #00000040"}

						_hover={{ bg: "transparent" }}
						_active={{ bg: "transparent" }}
						_focus={{ boxShadow: "none" }}
						onClick={(e) => {
							console.log('app url', myAppUrl);
							e.stopPropagation();
							const title = searchDetail?.videos[currentVideoIndex]?.title
								? searchDetail?.videos[currentVideoIndex]?.title
								: searchDetail?.videos[currentVideoIndex]?.video_play_url?.title;
							const link = `${myAppUrl}/video-detail?link=${searchDetail?.videos[currentVideoIndex]?.video_url}`;
							console.log('link', link);
							amplitude.track('share', { type: 'video', title: title, page: 'video_search', url: link });

							handleShareClick(title, link);
						}}
					>
						<Image src="/icons/white-share.svg" alt="share" />
					</IconButton>
				</HStack>
			</Box>
			{showAddedToBucket && (
				<Flex
					position='fixed'
					bottom='10px'
					left='50%'
					transform='translateX(-50%)'
					bg='#AE7CFF'
					w={'300px'}
					h={'40px'}
					color='white'
					borderRadius='8px'
					alignItems={'center'}
					justifyContent={'space-around'}
					zIndex={200}>
					<Text
						fontFamily='Poppins'
						fontSize='18px'
						fontWeight={'medium'}
						lineHeight='19px'
						letterSpacing='-0.5611507892608643px'
						textAlign='left'>
						Added to bucket list
					</Text>
					<Button
						variant={'ghost'}
						color={'white'}
						_hover={{ bg: 'none' }}
						fontWeight={'medium'}
						onClick={() => navigate('/navigate')}>
						View
					</Button>
				</Flex>
			)}
		</>
	);
};

export default LikeAndShare;
