import React, { useContext, useEffect, useState } from "react";
import {
    Box,
    // Container,
    Flex,
    // Heading,
    VStack,
    // Checkbox,
    // Divider,
    Text,
    Image,
    // Grid,
    // GridItem,
    // Button,
    Spinner,
    IconButton,
    // IconButton,
} from "@chakra-ui/react";
import { AppContext } from "../../context/AppContext";
import useAxios from "../../hooks/useAxios";
// import useTripService from "../../services/tripService";
import Cards from "./Cards";
import TrPrimaryButton from "../TrPrimaryButton";

interface SelectItemProps {
    handleClose: () => void;
}

const SelectItem: React.FC<SelectItemProps> = ({ handleClose }) => {
    const context: any = useContext(AppContext);
    const {
        mustHave,
        setMustHave,
        goodToHave,
        setGoodToHave,
        likeEntities,
        itineraryQuery,
    } = context;
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { fetchData } = useAxios();
    // const { fetchConversationResponse } = useTripService();

    const getHighlights = () => {
        const fetchHighlights = async () => {
            setIsLoading(true); // Start loading
            try {
                const { data } = await fetchData({
                    method: "POST",
                    url: `get_all_highlights_by_country`,
                    options: {
                        data: {
                            token: localStorage.getItem("token"),
                            country:
                                itineraryQuery[itineraryQuery?.length - 1]?.meta
                                    ?.destination_country,
                            // "India",
                            city:
                                itineraryQuery[itineraryQuery?.length - 1]?.meta
                                    ?.destination_city[0],
                            // "Varanasi",
                        },
                        headers: { "Content-Type": "application/json" },
                    },
                    auth: true,
                });
                if (data) {
                    setGoodToHave(data.highlights);
                }
            } catch (error) {
                console.error("Error fetching highlights:", error);
            } finally {
                setIsLoading(false); // End loading regardless of success/failure
            }
        };
        fetchHighlights();
    };

    const initialise = () => {
        // setMustHave([]);
        // setGoodToHave([]);
        const country =
            itineraryQuery[itineraryQuery?.length - 1]?.meta?.destination_country;
        const city =
            itineraryQuery[itineraryQuery?.length - 1]?.meta?.destination_city[0];

        const must = Array.isArray(likeEntities)
            ? likeEntities
                // First filter by country (case-insensitive)
                .filter(
                    (entity: any) =>
                        entity.country?.toLowerCase() === country?.toLowerCase()
                )
                // Then filter by city only if city is valid
                .filter((entity: any) => {
                    if (!city || city === "") return true;
                    return entity.city?.toLowerCase() === city?.toLowerCase();
                })
                .map((entity: any) => ({
                    id: entity.entity_id,
                    title: entity.title,
                    city: entity.city,
                    country: entity.country,
                    image: entity.image,
                    checked: true,
                    // verified: entity.verified,  
                }))
            : [];

        setMustHave(must);
    };
    useEffect(() => {
        if ((mustHave?.length === 0) && (goodToHave?.length === 0)) {
            initialise();
            getHighlights();
        }
        // eslint-disable-next-line
    }, []);

    const handleProcess = async () => {
        const bucketList = {
            must_include: mustHave,
            good_to_have: goodToHave,
        };
        localStorage.setItem("bucketList", JSON.stringify(bucketList));
        // fetchConversationResponse("Create a trip with the above preferences");
        handleClose();
    };

    return (
        <Box
            width="100%"
            height="100%"
            minH={"100vh"}
            bg="black"
            position="relative"
            overflow="hidden"
            zIndex={999999}
        >
            <Box bg="linear-gradient(180deg, #252128 0%, rgba(36, 29, 50, 0) 100%)"
                w={"100%"}
                h={"100%"}
                p={5}
            >
                {isLoading && (
                    <Flex
                        position="fixed"
                        top="0"
                        left="0"
                        right="0"
                        bottom="0"
                        bg="rgba(0, 0, 0, 0.7)"
                        zIndex="9999"
                        justifyContent="center"
                        alignItems="center"
                        backdropFilter="blur(5px)"
                    >
                        <Box textAlign="center">
                            <Spinner
                                size="xl"
                                color="purple.500"
                                thickness="4px"
                                speed="0.65s"
                            />
                            <Text mt={4} color="white">
                                Loading saved items...
                            </Text>
                        </Box>
                    </Flex>
                )}
                <VStack
                    // mt={3}
                    w={"100%"}
                    spacing={4} alignItems={"flex-start"} mb={4}>
                    <IconButton
                        aria-label="Go Back"
                        icon={
                            <Image
                                src="/icons/Back_Icon_White.svg"
                                alt="back"
                                filter="invert(57%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(95%) contrast(90%)"
                            />
                        }
                        variant="ghost"
                        onClick={handleClose}
                    />

                    <Box>
                        <Text
                            fontFamily="Poppins"
                            fontWeight="700"
                            fontSize="16px"
                            lineHeight="13px"
                            letterSpacing="-0.3px"
                            color={'#FFFFFFD4'}
                        >
                            {"Showing saved items in \"" + itineraryQuery[itineraryQuery?.length - 1]?.meta?.destination + "\""}
                        </Text>
                        <Text
                            mt={3}
                            w={"87%"}
                            fontFamily="Poppins"
                            fontWeight="500"
                            fontSize="13px"
                            lineHeight="133%"
                            letterSpacing="-0.3px"
                            color={'#FFFFFFA1'}
                        >
                            Please select the ones you think are must haves in your itinerary
                        </Text>
                    </Box>


                    <Cards mustHave={mustHave} goodToHave={goodToHave} setMustHave={setMustHave} setGoodToHave={setGoodToHave} />

                    {(mustHave?.length || goodToHave?.length) ? (<TrPrimaryButton
                        onClick={() => {
                            // amplitude.track('button_clicked', { buttonName: 'Book', title: ?.title });
                            handleProcess();
                        }}
                        w={"100%"}
                        h={'52px'}
                        fontFamily="Poppins"
                        fontWeight="700"
                        fontSize="15px"
                        lineHeight="133%"
                        letterSpacing="0px"
                        textAlign="center"
                    >
                        Proceed
                    </TrPrimaryButton>) :
                        (
                            <VStack w={"100%"} h={"100%"} justifyContent={"center"} alignItems={"center"} mt={10}>
                                <Text
                                    color="rgba(255, 255, 255, 0.45)"
                                    textAlign="center"
                                    fontFamily="Poppins"
                                    fontSize="14px"
                                    fontStyle="normal"
                                    fontWeight="500"
                                    lineHeight="133%"
                                >
                                    No items available to display. Start by adding items to your bucketlist to see them here.
                                </Text>
                            </VStack>
                        )
                    }
                </VStack>
            </Box>
        </Box>
    );
};

export default SelectItem;