import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  VStack,
  Box,
  Text,
  HStack,
  Flex,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";
import * as amplitude from "@amplitude/analytics-browser";
import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ListItinerary from "./ListItinerary";
import MyBucketList from "../../pages/MyBucketList";
import ItineraryFooter from "./ItineraryFooter";
import CustomModal from "../CustomModal";
import { AppContext } from "../../context/AppContext";
import useAxios from "../../hooks/useAxios";
import Header from "../LandingPage/Header";
import useAuthRouteProtector from "../../hooks/useAuthRouteProtector";
import SavedConversation from "../TravelAdvisor/SavedConversation";

const NavigationTabs = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const context: any = useContext(AppContext);
  const router = useNavigate();
  const location = useLocation();
  const { setLikeEntities, setLikeSearches, setCityImage, userDetail } = context;
  const { fetchData } = useAxios();
  const { isAuthenticated } = useAuthRouteProtector();
  const [tabIndex, setTabIndex] = useState(location?.state?.index ?? 1);
  const [once, setOnce] = useState(true);

  // Fetch user data for likes (searches and entities)
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const [likeSearchesResponse, likeEntitiesResponse] = await Promise.all([
          fetchData({
            method: "POST",
            url: "user/getUserLikeSearches",
            options: {
              data: { token: localStorage.getItem("token") },
              headers: { "Content-Type": "application/json" },
            },
            auth: false,
          }),
          fetchData({
            method: "POST",
            url: "user/getUserLikeEntities",
            options: {
              data: { token: localStorage.getItem("token") },
              headers: { "Content-Type": "application/json" },
            },
            auth: false,
          }),
        ]);

        const likeSearchesData = likeSearchesResponse.data.data;
        const likeEntitiesData = likeEntitiesResponse.data.data;

        setLikeSearches(likeSearchesData.like_searches);
        setCityImage((prev: any) => ({ ...prev, ...likeSearchesData.city_image }));
        setCityImage((prev: any) => ({ ...prev, ...likeEntitiesData.city_image }));
        setLikeEntities(likeEntitiesData.like_entities);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (localStorage.getItem("token") && once) {
      fetchUserData();
      setOnce(false);
    }
  }, [fetchData, setLikeEntities, setLikeSearches, setCityImage, once]);

  useEffect(() => {
    console.log("tabIndex", tabIndex);
  }, [tabIndex]);

  const handleTabChange = (index: number) => {
    if (index === 2) {
      if (tabIndex !== 2) {
        setTabIndex(2); // Only update if needed
        // if (toShow === "DEV" || userDetail?.test_user) { }
        // else
        //   onOpen(); // Open modal when navigating to 'Saved Itineraries'
      }
    } else if (index === 3) {
      // Handle Previous Chat tab
      setTabIndex(3);
    } else {
      if (isOpen) {
        onClose(); // Close modal first if open
      }
      setTabIndex(1); // Switch to 'Bucketlists'
    }
  };

  const handleBackButton = () => router(-1);
  const handleCloseModal = () => {
    onClose(); // Close the modal first
    setTimeout(() => {
      setTabIndex(1); // Set the tab index after the modal is closed
    }, 0);
  };
  // useEffect(() => {
  //   if (location?.state && location?.state?.index !== undefined)
  //     setTabIndex(location.state.index);
  // }, []);

  return (
    <>
      <Box>
        <VStack bg="black" minH="100dvh" w="100vw" pt={2}>

          <Box
            position="fixed"
            top={0}
            left={0}
            w="100%"
            overflow={"auto"}
            h="100dvh"
            zIndex="2"
            // bg="linear-gradient(180deg, #252128 0%, rgba(36, 29, 50, 0) 100%)"
            bg="#111012"

          />
          <Tabs
            isLazy
            w={["100%", "85%", "85%", "85%"]}
            variant="unstyled"
            zIndex={5}
            index={tabIndex}
            onChange={handleTabChange}
            defaultIndex={1}
          >
            <Box w="100%" position="fixed" zIndex={9999} top={0} left={0} >
              <TabList bg={["#252128", "transparent", "transparent"]} zIndex={9999} left={0} top={0}>
                <VStack w={"100%"} >
                  <Box display={["none", "block", "block"]}>
                    <Header onOpenLogin={onOpen} isAuthenticated={isAuthenticated} />
                  </Box>
                  <VStack w={["100%", "85%", "85%", "85%"]} borderBottom={["1px solid #FFFFFF21"]} alignItems={["center", "flex-start", "flex-start"]} >
                    <HStack w={["100%", "20%", "20%"]} justifyContent="space-between">
                      <Tab p="2px" display={["block", "none", "none",]} >
                        <HStack w="10%">
                          <IconButton
                            aria-label="Back"
                            icon={<img src="/icons/Back_Icon_White.svg" alt="back" />}
                            colorScheme="gray"
                            size="lg"
                            variant="link"
                            onClick={handleBackButton}
                          />
                        </HStack>
                      </Tab>
                      <Tab
                        w={["45%", "50%", "50%"]}
                        _selected={{ borderBottom: "2px solid #FFFFFFED" }}
                        justifyContent={['center', 'flex-start', 'flex-start']}
                        px={[0, 0, 1]}
                      >
                        <Flex direction="column" alignItems="flex-start" >
                          <Text color="#FFFFFF54" fontFamily="Poppins" fontSize="14px" fontWeight="500">
                            Your
                          </Text>
                          <Text color="#FFFFFFED" fontFamily="Poppins" fontSize="14px" fontWeight="700">
                            Bucketlist
                          </Text>
                        </Flex>
                      </Tab>
                      <Tab
                        w={["45%", "50%", "50%"]}
                        _selected={{ borderBottom: "2px solid #FFFFFFED" }}
                        justifyContent={['center', 'flex-start', 'flex-start']}
                        px={[0, 0, 1]}
                      >
                        <Flex direction="column" alignItems="flex-start" onClick={() => {
                          amplitude.track("button_clicked", {
                            buttonName: "Saved Itineraries",
                          });
                        }}>
                          <Text color="#FFFFFF54" fontFamily="Poppins" fontSize="14px" fontWeight="500">
                            Saved
                          </Text>
                          <Text color="#FFFFFFED" fontFamily="Poppins" fontSize="14px" fontWeight="700">
                            Itineraries
                          </Text>
                        </Flex>
                      </Tab>
                      <Tab
                        w={["45%", "50%", "50%"]}
                        _selected={{ borderBottom: "2px solid #FFFFFFED" }}
                        justifyContent={['center', 'flex-start', 'flex-start']}
                        px={[0, 0, 1]}
                      >
                        <Flex direction="column" alignItems="flex-start" onClick={() => {
                          amplitude.track("button_clicked", {
                            buttonName: "Saved Itineraries",
                          });
                        }}>
                          <Text color="#FFFFFF54" fontFamily="Poppins" fontSize="14px" fontWeight="500">
                            Previous
                          </Text>
                          <Text color="#FFFFFFED" fontFamily="Poppins" fontSize="14px" fontWeight="700">
                            Chats
                          </Text>
                        </Flex>
                      </Tab>
                    </HStack>
                  </VStack>
                </VStack>


              </TabList>
            </Box>
            <TabPanels mt={10}>
              <TabPanel />
              <TabPanel>
                <MyBucketList />
              </TabPanel>
              <TabPanel>
                <ListItinerary />
              </TabPanel>
              <TabPanel>
                <SavedConversation />
              </TabPanel>
            </TabPanels>
          </Tabs>
          <Box mt={20} />
        </VStack>
        <ItineraryFooter />
      </Box >
      {/* Modal */}
      < CustomModal isOpen={isOpen} onClose={handleCloseModal} />
    </>
  );
};

export default NavigationTabs;