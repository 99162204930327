import { Flex, Button, Image, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
// import { transformMessage } from "../../utilities/transformMessage";
import { useConversationService } from '../../services/conversationService';
import useSmartNavigate from "../../hooks/useSmartNavigate";

// Define props interface
interface ConversationCardProps {
    title: string;
    image: string;
    conversation_id: string;
    type: string;
}

const ConversationCard: React.FC<ConversationCardProps> = ({ title, image, conversation_id, type }) => {
    const navigate = useSmartNavigate();
    const { getConversationById, processConversationMessages } = useConversationService();
    const context: any = useContext(AppContext);
    const { setChatEntries, setConversationId, setPreviousChat, setCurrChatId, setTitle } = context;

    const handleOpenChat = async () => {
        const token = localStorage.getItem('token');
        if (type === 'query') {
            if (token) {
                const data = await getConversationById({ conversation_id, type });
                if (data) {
                    setCurrChatId(data.conversation_id);
                    setTitle(data.title);
                    await setChatEntries([]);
                    const transformedMessages = processConversationMessages(data.messages);
                    setChatEntries(transformedMessages);
                    navigate('/chat');
                }
            }
        }
        else {
            setConversationId(conversation_id);

            const data = await getConversationById({ conversation_id, type });
            setTitle(data.title);

            if (data) {
                setConversationId(data.conversation_id);
                setTitle(data.title);
                await setPreviousChat([]);
                const transformedMessages = processConversationMessages(data.messages);
                setPreviousChat(transformedMessages);
                navigate('/conversation');
            }
        }
    }

    return (
        <Flex
            bg="#242127"
            // p={3}
            borderRadius={'12px'}
            align="center"
            py={4}
            justify="space-evenly"
            w={'100%'}
            mx={'20px'}
            onClick={handleOpenChat}
            border={'1px solid #FFFFFF12'}
        >
            {/* Image and Title */}

            {/* {image ? ( */}
            <Image
                src={image === "" ? "/images/defaultConversation.png" : image} // Display the image if available
                boxSize="50px"
                rounded="md"
                border="1.06px solid #313030"
            />
            {/* ) : (
                <Box
                    width="50px"
                    height="50px"
                    borderRadius="8px"
                    backgroundColor="gray.600"
                    marginRight="12px"
                />

            )} */}


            <Text
                ml={2}
                mr={3}
                fontFamily="Poppins"
                fontSize="13px"
                w={'107.62px'}
                h={'max-content'}
                fontWeight="500"
                noOfLines={2}
                lineHeight="17px"
                letterSpacing="-0.465063214302063px"
                textAlign="left"
                color={'#FFFFFFBA'}
            >{title}
            </Text>

            {/* Open Chat Button */}
            <Button bg={'#2B2A2C'} borderRadius={'99px'} variant="solid" py={3} >
                <Text

                    fontFamily="Poppins"
                    fontSize="13px"
                    fontWeight="600"
                    lineHeight="26.94px"
                    // onClick={handleOpenChat}
                    textAlign="left"
                    color={'#AE7CFF'}>
                    + Open Chat

                </Text>
            </Button>
        </Flex>
    );
};

export default ConversationCard;
